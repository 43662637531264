/* eslint-disable react/no-danger */
import React from 'react';
import ListNumbered from '@latitude/list-numbered/ListNumbered';
import PageData from '@/data/json/HowToApply/how-to-apply.json';
import { Heading4 } from '../Heading/Heading';
import { COLOR, MARGIN } from '../../utils/constants';

const HowToApplyList = props => {
  const { id = 'how-to-apply', variant, noHeading } = props;

  let data = [];

  switch (variant) {
    case 'vehicle':
      data = PageData.howToApplyForVehicle;
      break;
    case 'lowRateMasterCard':
      data = PageData.howToApplyForLowRateMasterCard;
      break;
    case 'infinityRewardsCard':
      data = PageData.howToApplyForInfinityRewardsCard;
      break;
      case 'sym':
      data = PageData.howToApplyForSym;
      break;
    default:
      data = PageData.howToApply;
  }

  return (
    <React.Fragment>
      {!noHeading && (
        <Heading4
          color={COLOR.BLACK}
          marginBottom={MARGIN.M24}
          isResponsive={false}
          id={id}
        >
          How to apply
        </Heading4>
      )}
      <ListNumbered
        data={data}
        viewStyle="compact"
        counterBgColor={COLOR.BLUE_DEEP}
        counterVerticalAlign
        separator="light"
      />
    </React.Fragment>
  );
};

export default HowToApplyList;
